import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import logoutIcon from '../assets/dashboard/logout.svg';
import axios from "axios";
import Header from './Header'
import Footer from './Footer'

import check from '../assets/dashboard/check.svg';
import '../assets/css/reset.css';
import '../assets/css/global.css';
import '../assets/css/style.css';
import '../assets/css/responsive.css';
const oneDay = 1000 * 60 * 60 * 24;

//const baseUrl = "https://studiguide-api.azurewebsites.net";
const baseUrl = process.env.REACT_APP_BASE_URL;

const Dashboard = () => {
    const [planId, setPlanID] = useState(1);
    const [data, setData] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [cancellationDetails, setcancellationDetails] = useState(null);
    const [plans, setPlan] = useState([]);
    const navigate = useNavigate();
    const SubmitHandler = (event) =>{
        event.preventDefault();
        logout();
      };
    useEffect(() => {
        const userId = getCookie('userId');
        axios.get(baseUrl + "/profile?user_id="+userId)
            .then(response => {
                //console.log('(response',response);
                setData(response.data);
            })
            .catch(error => {
                console.log(error);
                document.cookie = `isLoggedin=; max-age=`+oneDay+`; secure; samesite=none; path=/`;
                document.cookie = `userId=; max-age=` +oneDay +`; secure; samesite=none; path=/`;
                navigate('/login');
            });

        axios.get(baseUrl + "/get-user-subscription?user_id="+userId)
            .then(subscriptionInfo => {
                //console.log('(subscriptionInfo',subscriptionInfo.data.subscription.membership_status);
                setSubscription(subscriptionInfo.data.subscription);
                setcancellationDetails(subscriptionInfo.data.cancellationDetails);
            })
            .catch(error => {
                console.log(error);
            });
            getPlans();
    },[navigate])

    function logout(){
        axios
        .get(baseUrl + "/logout")
        .then(response => {
            //console.log(response);
            document.cookie = `isLoggedin=; max-age=`+oneDay+`; secure; samesite=none; path=/`;
            document.cookie = `userId=; max-age=` +oneDay +`; secure; samesite=none; path=/`;
            navigate('/login');
        })
        .catch(error =>{
            console.log(error);
        });
    }
    function getPlans(){
        axios
        .get(baseUrl + "/plans")
        .then((response) => {
          //console.log("(response", response);
          setPlan(response.data.res);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    function getCookie(name) {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
          const [cookieName, cookieValue] = cookie.trim().split('=');
          if (cookieName === name) {
            return cookieValue;
          }
        }
        return null;
      }

    const deleteMyAccount = async (e) => {
        e.preventDefault();
        try {
            const userId = getCookie('userId');
            axios.get(baseUrl + "/delete-user-account?user_id="+userId)
            .then(subscriptionInfo => {
                logout();
            })
            .catch(error => {
                logout();
                console.log(error);
            });
        } catch (error) {
            logout();
            console.error('Error canceling subscription', error);
        }

    }  
    const checkoutWithPlan = async (e) => {
        e.preventDefault();
        const userId = getCookie('userId');
        const data = { price_id:planId,user_id:userId };
        if (planId === 2) {
            axios
            .post(baseUrl + "/create-checkout-session-free", data)
            .then((response) => {
                //console.log(response);
                const checkoutUrl = response.data.checkoutUrl;
                window.location.href = checkoutUrl;
            })
            .catch((error) => {
                console.log(error);
            });
        }else{
           axios
          .post(baseUrl + "/create-checkout-session", data)
          .then((response) => {
            //console.log(response);
            const checkoutUrl = response.data.checkoutUrl;
            window.location.href = checkoutUrl;
          })
          .catch((error) => {
            console.log(error);
           });
        }
    }
    const handleCancelSubscription = async (e) => {
        e.preventDefault();
        try {
            const userId = getCookie('userId');
            axios.get(baseUrl + "/cancel-subscription?user_id="+userId)
            .then(subscriptionInfo => {
                //console.log('(subscriptionInfo',subscriptionInfo.data.subscription.membership_status);
                setSubscription(subscriptionInfo.data.subscription);
                setcancellationDetails(subscriptionInfo.data.cancellationDetails);
            })
            .catch(error => {
                console.log(error);
            });
        } catch (error) {
            // Handle error
            console.error('Error canceling subscription', error);
            // Handle error UI or other actions
        }
    };
    return (
        <div id="wrapper">
            <Header />
            <section className="dashbord-section">
                <div className="container">
                    <div className="global-fom dashbord-box primary-bg page-title mt-5">
                        <form>
                            <div className="logout-btn d-flex justify-content-end">
                                <button onClick={SubmitHandler}><img src={logoutIcon} alt="img" /> Log Out</button>
                            </div>
                            <div className="page-title pb-4 text-center">
                                <h2 className="position-relative">{data && <p>{data.Name}</p>}</h2>
                                <p className="jone-date pt-1">{data && <p>Joined on {data.Date}</p>}</p>
                                <span className="jhon-email d-flex align-items-center justify-content-center pt-3"><i className="fa fa-envelope" aria-hidden="true"></i> {data && <p>{data.Email}</p>}</span>
                            </div>

                            <div className="login-google-btn pt-2">
                                <ul>
                                    <li><a href='https://chrome.google.com/webstore/detail/testanswers-ai/idmpanpgekfkgfhoikgdikhncnbiaalp' className="login-btn chorme-with"><i className="fa fa-chrome" aria-hidden="true"></i>Download Chrome Extension</a></li>
                                    {/* <li><button className="google-with try-studi mt-3 position-relative">Try TestAnswers AI Chat <span className="notification">New</span></button></li> */}

                                </ul>
                            </div>
                        </form>
                    </div>
                    {subscription && (subscription.membership_status === "active" || subscription.membership_status === "trialing") && (cancellationDetails.reason == null || cancellationDetails.reason === undefined) ? (
    <div className="global-fom primary-bg page-title dashbord-box mt-5">
        <form>
            <div className="page-title pb-4 text-center">
                <h2 className="position-relative small-font">Current subscription</h2>
            </div>
            <div className="mb-3 pt-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Plan<span className="ps-2 email-star">*</span></label>
                <select className="form-select" aria-label="Default select example" disabled>
                    <option selected>{subscription.stripe_product} (${subscription.price_usd})</option>
                </select>
            </div>
            <div className="login-google-btn pt-4">
                <ul>
                    <li><button className="login-btn chorme-with fnish-account" onClick={handleCancelSubscription}>Cancel subscription</button></li>
                </ul>
            </div>
        </form>
    </div>
) : (
    subscription && (subscription.membership_status === "active" || subscription.membership_status === "trialing") && cancellationDetails && cancellationDetails.reason === "cancellation_requested" ? (
        <div className="global-fom primary-bg page-title dashbord-box mt-5">
            <form>
                <div className="page-title pb-4 text-center">
                    <h2 className="position-relative small-font">Current subscription cancelled</h2>
                </div>
                <div className="mb-3 pt-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Plan<span className="ps-2 email-star">*</span></label>
                    <select className="form-select" aria-label="Default select example" disabled>
                        <option selected>{subscription.stripe_product} (${subscription.price_usd})</option>
                    </select>
                </div>
                <div className="upgrade-discraption">
                    <ul>
                        <li><img src={check} alt="img" /> your current subscription cancellation requested</li>
                    </ul>
                </div>
                <div className="login-google-btn pt-4">
                    <ul>
                        <li><button className="login-btn chorme-with fnish-account disabled-btn" onClick={handleCancelSubscription}>Cancellation requested</button></li>
                    </ul>
                </div>
            </form>
        </div>
    ) : (
        <div className="global-fom primary-bg page-title dashbord-box mt-5">
            <form>
                <div className="page-title pb-4 text-center">
                    <h2 className="position-relative small-font">Upgrade for Full Access</h2>
                </div>
                <div className="mb-3 pt-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Plan<span className="ps-2 email-star">*</span></label>
                    <select className="form-select" aria-label="Default select example" onChange={(e) => setPlanID(e.target.value)}>
                        {plans.map((plan) => (
                            <option value={plan.id} key={plan.id}>{plan.stripe_product} ({plan.price_usd}{plan.payment_schedule === "Yearly" ? " / yr" : plan.payment_schedule === "Monthly"? " / mo":" / 2 weeks"})</option>
                        ))}
                    </select>
                </div>
                <div className="upgrade-discraption">
                    <ul>
                        <li><img src={check} alt="img" /> Get your answers instantly with no limit</li>
                        <li className="pt-3"><img src={check} alt="img" /> Get unlimited access to all features</li>
                    </ul>
                </div>
                <div className="login-google-btn pt-4">
                    <ul>
                        <li><button className="login-btn chorme-with" onClick={checkoutWithPlan}>Buy Now</button></li>
                    </ul>
                </div>
            </form>
        </div>
    )
)}

                    {/* <div class=" global-fom primary-bg page-title dashbord-box mt-5">
                        <form>
                            <div class="page-title pb-4">
                                <h2 class="position-relative small-font verify-email">Verify Your Email</h2>
                            </div>
                            <div class="login-google-btn pt-2">
                                <ul>
                                    <li><button class="login-btn chorme-with"><i class="fa fa-envelope" aria-hidden="true"></i>Send Verification Email</button></li>
                                </ul>
                            </div>
                        </form>
                    </div> */}

                    {/* <div class=" global-fom primary-bg page-title dashbord-box mt-5">
                        <form>
                            <div class="page-title pb-4">
                                <h2 class="position-relative small-font verify-email">Finish Account Setup</h2>
                            </div>
                            <div class="login-google-btn pt-2">
                                <ul>
                                    <li><button class="login-btn chorme-with">Finish Account Set Up</button></li>
                                </ul>
                            </div>
                        </form>
                    </div> */}

                    <div class=" global-fom primary-bg page-title dashbord-box mt-5">
                        <form>
                            <div class="page-title pb-4">
                                <h2 class="position-relative small-font verify-email">Delete Account</h2>
                            </div>
                            <div class="login-google-btn pt-2">
                                <ul>
                                    <li><button class="login-btn chorme-with fnish-account " onClick={deleteMyAccount}><i class="fa fa-trash-o" aria-hidden="true"></i>Delete Account</button></li>
                                    <p class="finish-pra">You will be required to confirm your choice before deleting.</p>
                                </ul>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Dashboard
