import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../assets/css/reset.css";
import "../assets/css/global.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Plans = () => {
  const [plans, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(baseUrl + "/plans")
      .then((response) => {
        //console.log("(response", response);
        setData(response.data.res);
      })
      .catch((error) => {
        console.log(error);
        navigate("/login");
      });
  }, [navigate]);


  const handlePlanStart = (plan) => {

    if (isLoggedin === true || isLoggedin === "true") {
      // Handle the plan start button click
      //console.log(`Starting ${plan.stripe_product}`);
      const clientReferenceId = getClientReferenceId();
      const price_id = plan.id;
      const data = { price_id: price_id, user_id: userId, clientReferenceId: clientReferenceId };
      if (price_id === 2) {
        axios
          .post(baseUrl + "/create-checkout-session-free", data)
          .then((response) => {
            //console.log(response);
            const checkoutUrl = response.data.checkoutUrl;
            window.location.href = checkoutUrl;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        axios
          .post(baseUrl + "/create-checkout-session", data)
          .then((response) => {
            //console.log(response);
            const checkoutUrl = response.data.checkoutUrl;
            window.location.href = checkoutUrl;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      navigate("/register");
    }
  };
  const [isLoggedin, setLogin] = useState(false);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const isLoggedin = getCookie('isLoggedin');
    const userId = getCookie('userId');

    if (isLoggedin !== null) {
      setLogin(decodeURIComponent(isLoggedin));
    }
    if (userId !== null) {
      setUserId(decodeURIComponent(userId));
    }
  }, []);

  function getClientReferenceId() {
    return (window.Rewardful && window.Rewardful.referral) || ('checkout_' + (new Date()).getTime());
  }

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  }


  return (
    <section id="pricing" className="tuition-section tuition-page-section">
      <div className="container container-c">
        <div className="row pb-5">
          <div className="page-title text-center">
            <h2 className="position-relative d-inline-block">Tuition</h2>
          </div>
        </div>
        <div className="row mt-4 tuition-box">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6"
            >
              <div className="tuition-price-box">
                <article className="primary-bg text-center">
                  <h4>{plan.stripe_product}</h4>
                  {
                    plan.payment_schedule === "Week" ?
                      <span>
                        ${plan.price_usd}{" "}
                        <span class="week-price">
                          for 2
                          <br></br>
                          weeks
                        </span>
                      </span>
                      :
                      <span>
                        ${plan.price_usd}{" "}
                        <span>
                          {plan.payment_schedule === "Yearly" ? "/ yr" : "/ mo"}
                        </span>
                      </span>

                  }

                  <ul className="list-price">
                    <li className="d-flex">
                      <span>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <span>Unlimited Access to Chrome Extension & Chat</span>
                    </li>
                    {plan.payment_schedule === "Yearly" && (
                      <li className="d-flex">
                        <span>
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span>Save 75%</span>
                      </li>
                    )}
                  </ul>
                  <button onClick={() => handlePlanStart(plan)}>
                    Start now
                  </button>
                </article>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );

};

export default Plans;