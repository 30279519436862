import React from 'react'
import Header from './Header'
import Footer from './Footer'
import '../assets/css/reset.css';
import '../assets/css/global.css';
import '../assets/css/style.css';
import '../assets/css/responsive.css';
const Terms = () => {
  return (
    <div>
   <div id="wrapper" class="terms-page">
    <Header/>
    <section class="terms-of-use pt-0">
      <div class="container container-c">
        <div class="page-title pb-5 text-center">
          <h2 class="d-inline-block terms-tittle">Term of use</h2>
          <h4 class="terms-sub-hadding pt-2">Last April 18, 2023</h4>
        </div>
        <p>&nbsp;</p>

        <p><span><span><span>We are
                TestAnswers.AI (&quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; &quot;our&quot;), a company
                registered in Atlanta, GA.</span></span></span></p>

        <p><span><span><span>We
                operate the website https://TestAnswers.AI.com (the &quot;Site&quot;), as well as any other related
                products and services that refer or link to these legal terms (the &quot;Legal Terms&quot;)
                (collectively, the &quot;Services&quot;).</span></span></span></p>

        <p><span><span><span>You can
                contact us by email at </span></span></span><a href="mailto:TestAnswers.ai@gmail.com"><span><span><span><u>TestAnswers.ai@gmail.com</u></span></span></span></a><span
           ><span><span
               >.&nbsp;</span></span></span></p>

        <p><span><span><span>These
                Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of
                an entity (&quot;you&quot;), and TestAnswers.AI, concerning your access to and use of the Services. You
                agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these
                Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM
                USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.</span></span></span></p>

        <p><span><span><span
               >Supplemental terms and conditions or documents that may be posted on the Services
                from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our
                sole discretion, to make changes or modifications to these Legal Terms from time to time. We will alert
                you about any changes by updating the &quot;Last updated&quot; date of these Legal Terms, and you waive
                any right to receive specific notice of each such change. It is your responsibility to periodically
                review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have
                been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of
                the Services after the date such revised Legal Terms are posted.</span></span></span></p>

        <p><span><span><span>The
                Services are intended for users who are at least 13 years of age. All users who are minors in the
                jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be
                directly supervised by, their parent or guardian to use the Services. If you are a minor, you must have
                your parent or guardian read and agree to these Legal Terms prior to you using the
                Services.</span></span></span></p>

        <p><span><span><span>We
                recommend that you print a copy of these Legal Terms for your records.</span></span></span></p>

        <p><span><span><span
               ><strong>TABLE OF CONTENTS</strong></span></span></span></p>

        <p><span><span><span>1. OUR
                SERVICES</span></span></span></p>

        <p><span><span><span>2.
                INTELLECTUAL PROPERTY RIGHTS</span></span></span></p>

        <p><span><span><span>3. USER
                REPRESENTATIONS</span></span></span></p>

        <p><span><span><span>4. USER
                REGISTRATION</span></span></span></p>

        <p><span><span><span>5.
                PURCHASES AND PAYMENT</span></span></span></p>

        <p><span><span><span>6.
                SUBSCRIPTION AND AUTO-RENEWAL</span></span></span></p>

        <p><span><span><span>7.
                CANCELLATION</span></span></span></p>

        <p><span><span><span>8.
                SOFTWARE</span></span></span></p>

        <p><span><span><span>9. USER
                CONDUCT</span></span></span></p>

        <p><span><span><span>10. THIRD
                PARTY PLATFORMS</span></span></span></p>

        <p><span><span><span>11.
                PROHIBITED ACTIVITIES</span></span></span></p>

        <p><span><span><span>12.
                DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</span></span></span></p>

        <p><span><span><span>13. USER
                GENERATED CONTRIBUTIONS</span></span></span></p>

        <p><span><span><span>14.
                CONTRIBUTION LICENSE</span></span></span></p>

        <p><span><span><span>15.
                THIRD-PARTY WEBSITES AND CONTENT</span></span></span></p>

        <p><span><span><span>16.
                SERVICES MANAGEMENT</span></span></span></p>

        <p><span><span><span>17.
                PRIVACY POLICY</span></span></span></p>

        <p><span><span><span>18. TERM
                AND TERMINATION</span></span></span></p>

        <p><span><span><span>19.
                MODIFICATIONS AND INTERRUPTIONS</span></span></span></p>

        <p><span><span><span>20.
                GOVERNING LAW</span></span></span></p>

        <p><span><span><span>21.
                DISPUTE RESOLUTION</span></span></span></p>

        <p><span><span><span>22.
                CORRECTIONS</span></span></span></p>

        <p><span><span><span>23.
                DISCLAIMER</span></span></span></p>

        <p><span><span><span>24.
                LIMITATIONS OF LIABILITY</span></span></span></p>

        <p><span><span><span>25.
                INDEMNIFICATION</span></span></span></p>

        <p><span><span><span>26. USER
                DATA</span></span></span></p>

        <p><span><span><span>27.
                ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span></span></span></p>

        <p><span><span><span>28.
                CALIFORNIA USERS AND RESIDENTS</span></span></span></p>

        <p><span><span><span>29.
                MISCELLANEOUS</span></span></span></p>

        <p><span><span><span>30.
                CONTACT US</span></span></span></p>

        <p><span><span><span
               ><strong>1. OUR SERVICES</strong></span></span></span></p>

        <p><span><span><span>The
                information provided when using the Services is not intended for distribution to or use by any person or
                entity in any jurisdiction or country where such distribution or use would be contrary to law or
                regulation or which would subject us to any registration requirement within such jurisdiction or
                country. Accordingly, those persons who choose to access the Services from other locations do so on
                their own initiative and are solely responsible for compliance with local laws, if and to the extent
                local laws are applicable.</span></span></span></p>

        <p><span><span><span>The
                Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and
                Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your
                interactions would be subjected to such laws, you may not use the Services. You may not use the Services
                in a way that would violate the Gramm-Leach-Bliley Act (GLBA).</span></span></span></p>

        <p><span><span><span
               ><strong>2. INTELLECTUAL PROPERTY RIGHTS</strong></span></span></span></p>

        <p><span><span><span>Our
                intellectual property</span></span></span></p>

        <p><span><span><span>We are
                the owner or the licensee of all intellectual property rights in our Services, including all source
                code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics
                in the Services (collectively, the &quot;Content&quot;), as well as the trademarks, service marks, and
                logos contained therein (the &quot;Marks&quot;).</span></span></span></p>

        <p><span><span><span>Our
                Content and Marks are protected by copyright and trademark laws (and various other intellectual property
                rights and unfair competition laws) and treaties in the United States and around the
                world.</span></span></span></p>

        <p><span><span><span>The
                Content and Marks are provided in or through the Services &quot;AS IS&quot; for your personal,
                non-commercial use only.</span></span></span></p>

        <p><span><span><span>When you
                use the TestAnswers.AI extension, you grant us a non-exclusive, royalty-free, worldwide, sublicensable,
                and transferable license to use, store, display, reproduce, and distribute your quiz and assignment
                questions and answers in connection with the Extension and our public-facing search feature. This
                license is for the purpose of providing and improving our services and does not grant us the right to
                use your content for unrelated purposes.</span></span></span></p>

        <p><span><span><span>Your use
                of our Services</span></span></span></p>

        <p><span><span><span>Subject
                to your compliance with these Legal Terms, including the &quot;PROHIBITED ACTIVITIES (#prohibited)
                &quot; section below, we grant you a non-exclusive, non-transferable, revocable license
                to:</span></span></span></p>

        <p><span><span><span>* access
                the Services; and</span></span></span></p>

        <p><span><span><span>*
                download or print a copy of any portion of the Content to which you have properly gained
                access.</span></span></span></p>

        <p><span><span><span>solely
                for your personal, non-commercial use.</span></span></span></p>

        <p><span><span><span>Except as
                set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks
                may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded,
                translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose
                whatsoever, without our express prior written permission.</span></span></span></p>

        <p><span><span><span>If you
                wish to make any use of the Services, Content, or Marks other than as set out in this section or
                elsewhere in our Legal Terms, please address your request to: TestAnswers.ai@gmail.com. If we ever grant
                you the permission to post, reproduce, or publicly display any part of our Services or Content, you must
                identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright
                or proprietary notice appears or is visible on posting, reproducing, or displaying our
                Content.</span></span></span></p>

        <p><span><span><span>We
                reserve all rights not expressly granted to you in and to the Services, Content, and
                Marks.</span></span></span></p>

        <p><span><span><span>Any
                breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and
                your right to use our Services will terminate immediately.</span></span></span></p>

        <p><span><span><span>Your
                submissions</span></span></span></p>

        <p><span><span><span>Please
                review this section and the &quot;PROHIBITED ACTIVITIES (#prohibited) &quot; section carefully prior to
                using our Services to understand the (a) rights you give us and (b) obligations you have when you post
                or upload any content through the Services.</span></span></span></p>

        <p><span><span><span
               >Submissions: By directly sending us any question, comment, suggestion, idea,
                feedback, or other information about the Services (&quot;Submissions&quot;), you agree to assign to us
                all intellectual property rights in such Submission. You agree that we shall own this Submission and be
                entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise,
                without acknowledgment or compensation to you.</span></span></span></p>

        <p><span><span><span>You are
                responsible for what you post or upload: By sending us Submissions through any part of the Services
                you:</span></span></span></p>

        <p><span><span><span>* confirm
                that you have read and agree with our &quot;PROHIBITED ACTIVITIES (#prohibited) &quot; and will not
                post, send, publish, upload, or transmit through the Services any Submission that is illegal, harassing,
                hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or
                group, sexually explicit, false, inaccurate, deceitful, or misleading;</span></span></span></p>

        <p><span><span><span>* to the
                extent permissible by applicable law, waive any and all moral rights to any such
                Submission;</span></span></span></p>

        <p><span><span><span>* warrant
                that any such Submission are original to you or that you have the necessary rights and licenses to
                submit such Submissions and that you have full authority to grant us the above-mentioned rights in
                relation to your Submissions; and * warrant and represent that your Submissions do not constitute
                confidential information.</span></span></span></p>

        <p><span><span><span>You are
                solely responsible for your Submissions and you expressly agree to reimburse us for any and all losses
                that we may suffer because of your breach of (a) this section, (b) any third party&rsquo;s intellectual
                property rights, or (c) applicable law.</span></span></span></p>

        <p><span><span><span
               ><strong>3. USER REPRESENTATIONS</strong></span></span></span></p>

        <p><span><span><span>By using
                the Services, you represent and warrant that: (1) all registration information you submit will be true,
                accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly
                update such registration information as necessary; (3) you have the legal capacity and you agree to
                comply with these Legal Terms; (4) you are not under the age of 13; (5) you are not a minor in the
                jurisdiction in which you reside, or if a minor, you have received parental permission to use the
                Services; (6) you will not access the Services through automated or non-human means, whether through a
                bot, script or otherwise; (7) you will not use the Services for any illegal or unauthorized purpose; and
                (8) your use of the Services will not violate any applicable law or regulation.</span></span></span></p>

        <p><span><span><span>If you
                provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
                suspend or terminate your account and refuse any and all current or future use of the Services (or any
                portion thereof).</span></span></span></p>

        <p><span><span><span
               ><strong>4. USER REGISTRATION</strong></span></span></span></p>

        <p><span><span><span>You may
                be required to register to use the Services. You agree to keep your password confidential and will be
                responsible for all use of your account and password. We reserve the right to remove, reclaim, or change
                a username you select if we determine, in our sole discretion, that such username is inappropriate,
                obscene, or otherwise objectionable.</span></span></span></p>

        <p><span><span><span
               ><strong>5. PURCHASES AND PAYMENT</strong></span></span></span></p>

        <p><span><span><span>We accept
                the following forms of payment:</span></span></span></p>

        <p><span><span><span>-&nbsp;
                Visa</span></span></span></p>

        <p><span><span><span>-&nbsp;
                Mastercard</span></span></span></p>

        <p><span><span><span>-&nbsp;
                American Express</span></span></span></p>

        <p><span><span><span>-&nbsp;
                Discover</span></span></span></p>

        <p><span><span><span>-&nbsp;
                PayPal</span></span></span></p>

        <p><span><span><span>You agree
                to provide current, complete, and accurate purchase and account information for all purchases made via
                the Services. You further agree to promptly update account and payment information, including email
                address, payment method, and payment card expiration date, so that we can complete your transactions and
                contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We
                may change prices at any time. All payments shall be in US dollars.</span></span></span></p>

        <p><span><span><span>You agree
                to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and
                you authorize us to charge your chosen payment provider for any such amounts upon placing your order. If
                your order is subject to recurring charges, then you consent to our charging your payment method on a
                recurring basis without requiring your prior approval for each recurring charge, until such time as you
                cancel the applicable order. We reserve the right to correct any errors or mistakes in pricing, even if
                we have already requested or received payment.</span></span></span></p>

        <p><span><span><span>We
                reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit
                or cancel quantities purchased per person, per household, or per order. These restrictions may include
                orders placed by or under the same customer account, the same payment method, and/or orders that use the
                same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole
                judgment, appear to be placed by dealers, resellers, or distributors.</span></span></span></p>

        <p><span><span><span
               ><strong>6. SUBSCRIPTION AND AUTO-RENEWAL</strong></span></span></span></p>

        <p><span><span><span
               >TestAnswers.AI provides its services on a subscription basis. By subscribing to
                our services, you agree to pay the subscription fees for the plan you selected, which grants you
                unlimited access to our software solutions for students.</span></span></span></p>

        <p><span><span><span>We offer
                two plans: a monthly plan at the price of $16.99 and an annual plan at the price of $49.99. Upon
                subscribing, you authorize us to initiate a payment or series of payments on your behalf. Payments are
                processed by our payment partner, Stripe, which accepts a variety of payment methods including, but not
                limited to, credit card, debit card, Apple Pay, Cash App, Google Pay, and Link.</span></span></span></p>

        <p><span><span><span>The
                payment for the monthly plan is taken every month, while the payment for the annual plan is taken once a
                year. The subscription renews automatically at the end of each billing cycle at the rate agreed upon at
                the time of your subscription. Even if we change the pricing for our services in the future, your
                subscription fee will remain the same for the duration of your subscription.</span></span></span></p>

        <p><span><span><span>You may
                change from a monthly to an annual plan or vice versa, or cancel your subscription at any time by
                logging into your TestAnswers.AI account dashboard and clicking the &quot;Manage Subscription&quot;
                button. Please note that the cancellation will take effect at the end of the current billing cycle, and
                you will not receive a refund for the billing period in which you cancel.</span></span></span></p>

        <p><span><span><span>By
                subscribing to TestAnswers.AI services, you confirm your understanding of, and consent to, the
                subscription fees, the frequency of payments, and the automatic renewal of your
                subscription.</span></span></span></p>

        <p><span><span><span
               ><strong>7. CANCELLATION</strong></span></span></span></p>

        <p><span><span><span>You can
                cancel your subscription at any time by logging into your account. Your cancellation will take effect at
                the end of the current paid term.</span></span></span></p>

        <p><span><span><span>If you
                are unsatisfied with our Services, please email us at TestAnswers.ai@gmail.com.</span></span></span></p>

        <p><span><span><span
               ><strong>8. SOFTWARE</strong></span></span></span></p>

        <p><span><span><span>We may
                include software for use in connection with our Services. If such software is accompanied by an end user
                license agreement (&quot;EULA&quot;), the terms of the EULA will govern your use of the software. If
                such software is not accompanied by a EULA, then we grant to you a non-exclusive, revocable, personal,
                and non-transferable license to use such software solely in connection with our services and in
                accordance with these Legal Terms. Any software and any related documentation is provided &quot;AS
                IS&quot; without warranty of any kind, either express or implied, including, without limitation, the
                implied warranties of merchantability, fitness for a particular purpose, or non-infringement. You accept
                any and all risk arising out of use or performance of any software. You may not reproduce or
                redistribute any software except in accordance with the EULA or these Legal Terms.</span></span></span>
        </p>

        <p><span><span><span
               ><strong>9. USER CONDUCT</strong></span></span></span></p>

        <p><span><span><span
               ><strong>10. THIRD PARTY PLATFORMS</strong></span></span></span></p>

        <p><span><span><span>The
                TestAnswers.AI extension interacts with third-party LMS platforms. We are not responsible for the
                content, policies, or practices of these platforms, and your use of these platforms is subject to their
                respective terms and conditions. You are responsible for complying with the terms and conditions of the
                LMS platforms you use.</span></span></span></p>

        <p><span><span><span>By using
                the TestAnswers.AI extension, you agree not to engage in any activities that violate the terms and
                conditions of the LMS platforms you use, including, but not limited to, cheating, academic dishonesty,
                or any other form of misconduct. You are solely responsible for adhering to the policies and guidelines
                set forth by the LMS platforms.</span></span></span></p>

        <p><span><span><span
               ><strong>11. DISCLAIMER OF WARRANTIES AND LIMITATION OF
                  LIABILITY</strong></span></span></span></p>

        <p><span><span><span>The
                TestAnswers.AI extension is provided on an &quot;as is&quot; basis without warranties of any kind,
                either express or implied, including, but not limited to, implied warranties of merchantability, fitness
                for a particular purpose, and non-infringement. We do not warrant that the Extension will be error-free
                or that the answers provided will be accurate or reliable.</span></span></span></p>

        <p><span><span><span>In no
                event shall we be liable for any direct, indirect, incidental, special, consequential, or punitive
                damages, including, but not limited to, loss of profits, data, or other intangibles, resulting from your
                use of the TestAnswers.AI extension, even if we have been advised of the possibility of such
                damages.</span></span></span></p>

        <p><span><span><span
               ><strong>12. PROHIBITED ACTIVITIES</strong></span></span></span></p>

        <p><span><span><span>You may
                not access or use the Services for any purpose other than that for which we make the Services available.
                The Services may not be used in connection with any commercial endeavors except those that are
                specifically endorsed or approved by us.</span></span></span></p>

        <p><span><span><span>As a user
                of the Services, you agree not to:</span></span></span></p>

        <p><span><span><span>*
                Systematically retrieve data or other content from the Services to create or compile, directly or
                indirectly, a collection, compilation, database, or directory without written permission from
                us.</span></span></span></p>

        <p><span><span><span>* Trick,
                defraud, or mislead us and other users, especially in any attempt to learn sensitive account information
                such as user passwords.</span></span></span></p>

        <p><span><span><span>*
                Circumvent, disable, or otherwise interfere with security-related features of the Services, including
                features that prevent or restrict the use or copying of any Content or enforce limitations on the use of
                the Services and/or the Content contained therein.</span></span></span></p>

        <p><span><span><span>*
                Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</span></span></span></p>

        <p><span><span><span>* Use any
                information obtained from the Services in order to harass, abuse, or harm another
                person.</span></span></span></p>

        <p><span><span><span>* Make
                improper use of our support services or submit false reports of abuse or
                misconduct.</span></span></span></p>

        <p><span><span><span>* Use the
                Services in a manner inconsistent with any applicable laws or regulations.</span></span></span></p>

        <p><span><span><span>* Engage
                in unauthorized framing of or linking to the Services.</span></span></span></p>

        <p><span><span><span>* Upload
                or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including
                excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes
                with any party&rsquo;s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts,
                alters, or interferes with the use, features, functions, operation, or maintenance of the
                Services.</span></span></span></p>

        <p><span><span><span>* Engage
                in any automated use of the system, such as using scripts to send comments or messages, or using any
                data mining, robots, or similar data gathering and extraction tools.</span></span></span></p>

        <p><span><span><span>* Delete
                the copyright or other proprietary rights notice from any Content.</span></span></span></p>

        <p><span><span><span>* Attempt
                to impersonate another user or person or use the username of another user.</span></span></span></p>

        <p><span><span><span>* Upload
                or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
                information collection or transmission mechanism, including without limitation, clear graphics
                interchange formats (&quot;gifs&quot;), 1&times;1 pixels, web bugs, cookies, or other similar devices
                (sometimes referred to as &quot;spyware&quot; or &quot;passive collection mechanisms&quot; or
                &quot;pcms&quot;).</span></span></span></p>

        <p><span><span><span>*
                Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected
                to the Services.</span></span></span></p>

        <p><span><span><span>* Harass,
                annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the
                Services to you.</span></span></span></p>

        <p><span><span><span>* Attempt
                to bypass any measures of the Services designed to prevent or restrict access to the Services, or any
                portion of the Services.</span></span></span></p>

        <p><span><span><span>* Copy or
                adapt the Services&#39; software, including but not limited to Flash, PHP, HTML, JavaScript, or other
                code.</span></span></span></p>

        <p><span><span><span>* Except
                as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the
                software comprising or in any way making up a part of the Services.</span></span></span></p>

        <p><span><span><span>* Except
                as may be the result of standard search engine or Internet browser usage, use, launch, develop, or
                distribute any automated system, including without limitation, any spider, robot, cheat utility,
                scraper, or offline reader that accesses the Services, or use or launch any unauthorized script or other
                software.</span></span></span></p>

        <p><span><span><span>* Use a
                buying agent or purchasing agent to make purchases on the Services.</span></span></span></p>

        <p><span><span><span>* Make
                any unauthorized use of the Services, including collecting usernames and/or email addresses of users by
                electronic or other means for the purpose of sending unsolicited email, or creating user accounts by
                automated means or under false pretenses.</span></span></span></p>

        <p><span><span><span>* Use the
                Services as part of any effort to compete with us or otherwise use the Services and/or the Content for
                any revenue-generating endeavor or commercial enterprise.</span></span></span></p>

        <p><span><span><span>* Use the
                Services to advertise or offer to sell goods and services.</span></span></span></p>

        <p><span><span><span>* Sell or
                otherwise transfer your profile.</span></span></span></p>

        <p><span><span><span
               ><strong>13. USER GENERATED CONTRIBUTIONS</strong></span></span></span></p>

        <p><span><span><span>The
                Services does not offer users to submit or post content. We may provide you with the opportunity to
                create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and
                materials to us or on the Services, including but not limited to text, writings, video, audio,
                photographs, graphics, comments, suggestions, or personal information or other material (collectively,
                &quot;Contributions&quot;). Contributions may be viewable by other users of the Services and through
                third-party websites. As such, any Contributions you transmit may be treated in accordance with the
                Services&#39; Privacy Policy. When you create or make available any Contributions, you thereby represent
                and warrant that:</span></span></span></p>

        <p><span><span><span>* The
                creation, distribution, transmission, public display, or performance, and the accessing, downloading, or
                copying of your Contributions do not and will not infringe the proprietary rights, including but not
                limited to the copyright, patent, trademark, trade secret, or moral rights of any third
                party.</span></span></span></p>

        <p><span><span><span>* You are
                the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to
                use and to authorize us, the Services, and other users of the Services to use your Contributions in any
                manner contemplated by the Services and these Legal Terms.</span></span></span></p>

        <p><span><span><span>* You
                have the written consent, release, and/or permission of each and every identifiable individual person in
                your Contributions to use the name or likeness of each and every such identifiable individual person to
                enable inclusion and use of your Contributions in any manner contemplated by the Services and these
                Legal Terms.</span></span></span></p>

        <p><span><span><span>* Your
                Contributions are not false, inaccurate, or misleading.</span></span></span></p>

        <p><span><span><span>* Your
                Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes,
                chain letters, spam, mass mailings, or other forms of solicitation.</span></span></span></p>

        <p><span><span><span>* Your
                Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or
                otherwise objectionable (as determined by us).</span></span></span></p>

        <p><span><span><span>* Your
                Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</span></span></span></p>

        <p><span><span><span>* Your
                Contributions are not used to harass or threaten (in the legal sense of those terms) any other person
                and to promote violence against a specific person or class of people.</span></span></span></p>

        <p><span><span><span>* Your
                Contributions do not violate any applicable law, regulation, or rule.</span></span></span></p>

        <p><span><span><span>* Your
                Contributions do not violate the privacy or publicity rights of any third party.</span></span></span>
        </p>

        <p><span><span><span>* Your
                Contributions do not violate any applicable law concerning child pornography, or otherwise intended to
                protect the health or well-being of minors.</span></span></span></p>

        <p><span><span><span>* Your
                Contributions do not include any offensive comments that are connected to race, national origin, gender,
                sexual preference, or physical handicap.</span></span></span></p>

        <p><span><span><span>* Your
                Contributions do not otherwise violate, or link to material that violates, any provision of these Legal
                Terms, or any applicable law or regulation.</span></span></span></p>

        <p><span><span><span>Any use
                of the Services in violation of the foregoing violates these Legal Terms and may result in, among other
                things, termination or suspension of your rights to use the Services.</span></span></span></p>

        <p><span><span><span
               ><strong>14. CONTRIBUTION</strong></span></span></span></p>

        <p><span><span><span
               >LICENSE</span></span></span></p>

        <p><span><span><span>You and
                Services agree that we may access, store, process, and use any information and personal data that you
                provide following the terms of the Privacy Policy and your choices (including
                settings).</span></span></span></p>

        <p><span><span><span>By
                submitting suggestions or other feedback regarding the Services, you agree that we can use and share
                such feedback for any purpose without compensation to you.</span></span></span></p>

        <p><span><span><span>We do not
                assert any ownership over your Contributions. You retain full ownership of all of your Contributions and
                any intellectual property rights or other proprietary rights associated with your Contributions. We are
                not liable for any statements or representations in your Contributions provided by you in any area on
                the Services. You are solely responsible for your Contributions to the Services and you expressly agree
                to exonerate us from any and all responsibility and to refrain from any legal action against us
                regarding your Contributions.</span></span></span></p>

        <p><span><span><span
               ><strong>15. THIRD-PARTY WEBSITES AND CONTENT</strong></span></span></span></p>

        <p><span><span><span>The
                Services may contain (or you may be sent via the Site) links to other websites (&quot;Third-Party
                Websites&quot;) as well as articles, photographs, text, graphics, pictures, designs, music, sound,
                video, information, applications, software, and other content or items belonging to or originating from
                third parties (&quot;Third-Party Content&quot;). Such Third-Party Websites and Third-Party Content are
                not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are
                not responsible for any Third-Party Websites accessed through the Services or any Third-Party Content
                posted on, available through, or installed from the Services, including the content, accuracy,
                offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the
                Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or
                installation of any Third-Party Websites or any Third-Party Content does not imply approval or
                endorsement thereof by us. If you decide to leave the Services and access the Third-Party Websites or to
                use or install any Third-Party Content, you do so at your own risk, and you should be aware these Legal
                Terms no longer govern. You should review the applicable terms and policies, including privacy and data
                gathering practices, of any website to which you navigate from the Services or relating to any
                applications you use or install from the Services. Any purchases you make through Third-Party Websites
                will be through other websites and from other companies, and we take no responsibility whatsoever in
                relation to such purchases which are exclusively between you and the applicable third party. You agree
                and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you
                shall hold us blameless from any harm caused by your purchase of such products or services.
                Additionally, you shall hold us blameless from any losses sustained by you or harm caused to you
                relating to or</span></span></span></p>

        <p><span><span><span>resulting
                in any way from any Third-Party Content or any contact with Third-Party Websites.</span></span></span>
        </p>

        <p><span><span><span
               ><strong>16. SERVICES MANAGEMENT</strong></span></span></span></p>

        <p><span><span><span>We
                reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal
                Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or
                these Legal Terms, including without limitation, reporting such user to law enforcement authorities; (3)
                in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or
                disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4)
                in our sole discretion and without limitation, notice, or liability, to remove from the Services or
                otherwise disable all files and content that are excessive in size or are in any way burdensome to our
                systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property
                and to facilitate the proper functioning of the Services.</span></span></span></p>

        <p><span><span><span
               ><strong>17. PRIVACY POLICY</strong></span></span></span></p>

        <p><span><span><span>We care
                about data privacy and security. Please review our Privacy Policy: https://TestAnswers.AI.com/privacy.
                By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these
                Legal Terms. Please be advised the Services are hosted in the United States. If you access the Services
                from any other region of the world with laws or other requirements governing personal data collection,
                use, or disclosure that differ from applicable laws in the United States, then through your continued
                use of the Services, you are transferring your data to the United States, and you expressly consent to
                have your data transferred to and processed in the United States. Further, we do not knowingly accept,
                request, or solicit information from children or knowingly market to children. Therefore, in accordance
                with the U.S. Children&rsquo;s Online Privacy Protection Act, if we receive actual knowledge that anyone
                under the age of 13 has provided personal information to us without the requisite and verifiable
                parental consent, we will delete that information from the Services as quickly as is reasonably
                practical.</span></span></span></p>

        <p><span><span><span>Data
                Collection and Usage Policy:</span></span></span></p>

        <p><span><span><span>By using
                our service, you agree that we may collect and store data on the questions and answers you input into
                our chrome extension. We use this data to improve the accuracy of our service and to provide you with a
                better user experience.</span></span></span></p>

        <p><span><span><span>We may
                also collect other data related to your use of our service, such as your IP address and device
                information, to help diagnose problems with our servers and to administer our
                service.</span></span></span></p>

        <p><span><span><span>We do not
                sell or share your personal data with third parties without your explicit consent, except as required by
                law or to protect our rights and interests.</span></span></span></p>

        <p><span><span><span>We take
                data privacy and security seriously and employ industry-standard measures to safeguard your data from
                unauthorized access, use, or disclosure.</span></span></span></p>

        <p><span><span><span>By using
                our service, you acknowledge and agree to the terms of our privacy policy, which describes in detail the
                types of data we collect, how we use it, and your rights with respect to your data.</span></span></span>
        </p>

        <p><span><span><span
               ><strong>18. TERM AND TERMINATION</strong></span></span></span></p>

        <p><span><span><span>These
                Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER
                PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
                LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY
                PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
                WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
                TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
                INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</span></span></span>
        </p>

        <p><span><span><span>If we
                terminate or suspend your account for any reason, you are prohibited from registering and creating a new
                account under your name, a fake or borrowed name, or the name of any third party, even if you may be
                acting on behalf of the third party. In addition to terminating or suspending your account, we reserve
                the right to take appropriate legal action, including without limitation pursuing civil, criminal, and
                injunctive redress.</span></span></span></p>

        <p><span><span><span
               ><strong>19. MODIFICATIONS AND INTERRUPTIONS</strong></span></span></span></p>

        <p><span><span><span>We
                reserve the right to change, modify, or remove the contents of the Services at any time or for any
                reason at our sole discretion without notice. However, we have no obligation to update any information
                on our Services. We will not be liable to you or any third party for any modification, price change,
                suspension, or discontinuance of the Services.</span></span></span></p>

        <p><span><span><span>We cannot
                guarantee the Services will be available at all times. We may experience hardware, software, or other
                problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or
                errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the
                Services at any time or for any reason without notice to you. You agree that we have no liability
                whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services
                during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to
                obligate us to maintain and support the Services or to supply any corrections, updates, or releases in
                connection therewith.</span></span></span></p>

        <p><span><span><span
               ><strong>20. GOVERNING LAW</strong></span></span></span></p>

        <p><span><span><span>These
                Legal Terms and your use of the Services are governed by and construed in accordance with the laws of
                the State of New York applicable to agreements made and to be entirely performed within the State of New
                York, without regard to its conflict of law principles</span></span></span></p>

        <p><span><span><span
               ><strong>21. DISPUTE RESOLUTION</strong></span></span></span></p>

        <p><span><span><span>Any legal
                action of whatever nature brought by either you or us (collectively, the &quot;Parties&quot; and
                individually, a &quot;Party&quot;) shall be commenced or prosecuted in the state and federal courts
                located in New York County, New York, and the Parties hereby consent to, and waive all defenses of lack
                of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in such state
                and federal courts. Application of the United Nations Convention on Contracts for the International Sale
                of Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from these Legal
                Terms. In no event shall any claim, action, or proceeding brought by either Party related in any way to
                the Services be commenced more than one (1) years after the cause of action arose.</span></span></span>
        </p>

        <p><span><span><span
               ><strong>22. CORRECTIONS</strong></span></span></span></p>

        <p><span><span><span>There may
                be information on the Services that contains typographical errors, inaccuracies, or omissions, including
                descriptions, pricing, availability, and various other information. We reserve the right to correct any
                errors, inaccuracies, or omissions and to change or update the information on the Services at any time,
                without prior notice.</span></span></span></p>

        <p><span><span><span
               ><strong>23. DISCLAIMER</strong></span></span></span></p>

        <p><span><span><span>THE
                SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL
                BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
                IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE
                NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES&#39; CONTENT OR THE
                CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR
                RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
                INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
                SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
                TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
                CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
                CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE,
                GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY
                THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY
                BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
                ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
                OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
                EXERCISE CAUTION WHERE APPROPRIATE.</span></span></span></p>

        <p><span><span><span
               ><strong>24. LIMITATIONS OF LIABILITY</strong></span></span></span></p>

        <p><span><span><span>IN NO
                EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT,
                LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
                OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
                TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE one (1) mONTH PERIOD PRIOR TO ANY
                CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
                IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME
                OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
                RIGHTS.</span></span></span></p>

        <p><span><span><span
               ><strong>25. INDEMNIFICATION</strong></span></span></span></p>

        <p><span><span><span>You agree
                to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
                respective officers, agents, partners, and employees, from and against any loss, damage, liability,
                claim, or demand, including reasonable attorneys&rsquo; fees and expenses, made by any third party due
                to or arising out of: (1) use of the Services; (2) breach of these Legal Terms; (3) any breach of your
                representations and warranties set forth in these Legal Terms; (4) your violation of the rights of a
                third party, including but not limited to intellectual property rights; or (5) any overt harmful act
                toward any other user of the Services with whom you connected via the Services. Notwithstanding the
                foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any
                matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our
                defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or
                proceeding which is subject to this indemnification upon becoming aware of it.</span></span></span></p>

        <p><span><span><span
               ><strong>26. USER DATA</strong></span></span></span></p>

        <p><span><span><span>We will
                maintain certain data that you transmit to the Services for the purpose of managing the performance of
                the Services, as well as data relating to your use of the Services. Although we perform regular routine
                backups of data, you are solely responsible for all data that you transmit or that relates to any
                activity you have undertaken using the Services. You agree that we shall have no liability to you for
                any loss or corruption of any such data, and you hereby waive any right of action against us arising
                from any such loss or corruption of such data.</span></span></span></p>

        <p><span><span><span
               ><strong>27. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                  SIGNATURES</strong></span></span></span></p>

        <p><span><span><span>Visiting
                the Services, sending us emails, and completing online forms constitute electronic communications. You
                consent to receive electronic communications, and you agree that all agreements, notices, disclosures,
                and other communications we provide to you electronically, via email and on the Services, satisfy any
                legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC
                SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
                RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or
                requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which
                require an original signature or delivery or retention of non-electronic records, or to payments or the
                granting of credits by any means other than electronic means.</span></span></span></p>

        <p><span><span><span
               ><strong>28. CALIFORNIA USERS AND RESIDENTS</strong></span></span></span></p>

        <p><span><span><span>If any
                complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the
                Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North
                Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916)
                445-1254.</span></span></span></p>

        <p><span><span><span
               ><strong>29. MISCELLANEOUS</strong></span></span></span></p>

        <p><span><span><span>These
                Legal Terms and any policies or operating rules posted by us on the Services or in respect to the
                Services constitute the entire agreement and understanding between you and us. Our failure to exercise
                or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or
                provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all
                of our rights and obligations to others at any time. We shall not be responsible or liable for any loss,
                damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or
                part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that
                provision or part of the provision is deemed severable from these Legal Terms and does not affect the
                validity and enforceability of any remaining provisions. There is no joint venture, partnership,
                employment or agency relationship created between you and us as a result of these Legal Terms or use of
                the Services. You agree that these Legal Terms will not be construed against us by virtue of having
                drafted them. You hereby waive any and all defenses you may have based on the electronic form of these
                Legal Terms and the lack of signing by the parties hereto to execute these Legal
                Terms.</span></span></span></p>

        <p><span><span><span
               ><strong>30. CONTACT US</strong></span></span></span></p>

        <p><span><span><span>In order
                to resolve a complaint regarding the Services or to receive further information regarding use of the
                Services, please contact us at:</span></span></span></p>

        <p><span><span><span
               >TestAnswers.AI</span></span></span></p>

        <p><span><span><span
               >TestAnswers.ai@gmail.com</span></span></span></p>

        <p>&nbsp;</p>

      </div>
    </section>
  </div>
<Footer/>
    </div>
  )
}

export default Terms
