import Home from './component/Home';
import Price from './component/Price';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './component/Login';
import Search from './component/Search';
import Register from './component/Register';
import Terms from './component/Terms';
import Privacy from './component/Privacy';
import Dashboard from './component/Dashboard';
import Recover from './component/Recover';
import Reset from './component/Reset';
import PaymentSuccess from './component/PaymentSuccess';
import ProtectedRouts from './component/ProtectedRouts';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {


  return (
    <>
    <BrowserRouter>
    <ToastContainer />
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register/>} />
      <Route path="/terms" element={<Terms/>} />
      <Route path="/pricing" element={<Price/>} />
      <Route path="/privacy" element={<Privacy/>} />
      <Route path="/recover" element={<Recover/>} />
      <Route path="/reset" element={<Reset/>} />
      <Route element={<ProtectedRouts />}>
      <Route path="/" element={<Home/>} />
              {/* <Route path="/login" element={<Login/>} /> */}
              <Route path="/pricing" element={<Price/>} />
              <Route path="/search" element={<Search/>} />
              <Route path="/register" element={<Register/>} />
              <Route path="/terms" element={<Terms/>} />
              <Route path="/dashboard" element={<Dashboard/>} />
              <Route path="/payment-success" element={<PaymentSuccess/>} />
      </Route>
    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;


