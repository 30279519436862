import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import validate from "validate.js";
// import google_icon from '../assets/login/google-icon.svg';
import "../assets/css/reset.css";
import "../assets/css/global.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";

const oneDay = 1000 * 60 * 60 * 24;
//const BASE_URL = "https://studiguide-api.azurewebsites.net";
//const BASE_URL = "http://localhost:3000";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Login = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loginError, setloginError] = useState(false);
  const [loading, setLoading] = useState(false); // State to track loading
  const isLoggedin = getCookie("isLoggedin") || false;
  //console.log("isLoggedin == ", isLoggedin);
  if (isLoggedin) {
    //navigate('/dashboard');
    return <Navigate to="/dashboard" />;
  }

  const config = {
    headers: {
      origin: "https://www.testanswers.ai",
    },
  };

  function getCookie(name) {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split("=");
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  }

  const SubmitHandler = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    const data = { email, password };

    event.preventDefault();
    const form = event.target;

    const formData = new FormData(form);
    const loginData = Object.fromEntries(formData.entries());

    // Define validation rules
    const constraints = {
      email: {
        presence: true,
        email: true,
      },
      password: {
        presence: true,
        length: { minimum: 6 },
      },
    };

    // Perform validation
    const validationErrors = validate(loginData, constraints);
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }
    // Clear old error messages if no validation errors
    setErrors({});
    setloginError(false);
    setLoading(true); // Show loader

    axios
      .post(BASE_URL + "/login", data, config)
      .then((response) => {
        //console.log(response);
        const userId = response.data["userId"];
        //console.log("userId >>>", userId);
        document.cookie =
          `isLoggedin=true; max-age=` +
          oneDay +
          `; secure; samesite=none; path=/`;
        document.cookie =
          `userId=` +
          userId +
          `; max-age=` +
          oneDay +
          `; secure; samesite=none; path=/`;
        setLoading(false);
        navigate("/dashboard");
        event.target.reset();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setloginError(error.response.data.error);
      });
  };
  return (
    <div id="wrapper">
      <Header />
      <section className="login-form-section">
        <div className="container">
          <div className="login-form global-fom primary-bg page-title">
            <form action="" onSubmit={SubmitHandler}>
              <div className="page-title pb-4 text-center">
                <h2 className="position-relative">Login</h2>
              </div>
              <div className="mb-3 pt-2">
                <label for="exampleFormControlInput1" className="form-label">
                  Email<span className="ps-2 email-star">*</span>{" "}
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="exampleFormControlInput1"
                  placeholder="you@example.com"
                />
                {errors.email && (
                  <p className="text-danger">{errors.email.join(", ")}</p>
                )}
              </div>
              <div className="mb-3 pt-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Password<span className="ps-2 email-star">*</span>{" "}
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  id="exampleFormControlInput1"
                  placeholder="••••••••"
                />
                {errors.password && (
                  <p className="text-danger">{errors.password.join(", ")}</p>
                )}
              </div>
              <p className="">Forgot your password? <span id="forgot-password" onClick={() => navigate("/recover")}>Recover your account</span></p>
              {loginError && <p className="text-danger mt-2">{loginError}</p>}

              <div className="login-google-btn pt-4">
                <ul>
                  <li>
                    <button
                      type="submit"
                      className="login-btn"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Login"}
                    </button>
                  </li>

                  {/* <li><button className="google-with mt-3"><img src={google_icon} alt="img" /> Continue with google</button></li> */}
                </ul>
                <p className="text-center">
                  Don’t have an account?{" "}
                  <button onClick={() => navigate("/register")}>Sign Up</button>{" "}
                  Instead
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Login;
