import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Plans from "./Plans";
//import $ from "jquery";
//import { useNavigate } from "react-router-dom";
import "../assets/css/reset.css";
import "../assets/css/global.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";

const Price = () => {
  //const navigate = useNavigate();
  // const getPlans = () =>{
  //     axios.get(baseUrl + "/plans")
  //         .then(response => {
  //             console.log('(response',response);
  //             setData(response.data.res);
  //         })
  //         .catch(error => {
  //             console.log(error);
  //             navigate('/login');
  //         });
  // }
  // useEffect(() => {
  //     getPlans();
  // })

  // useEffect(() => {
  //     axios.get(baseUrl + "/plans")
  //         .then(response => {
  //             console.log('(response',response);
  //             setData(response.data);
  //         })
  //         .catch(error => {
  //             console.log(error);
  //             navigate('/login');
  //         });
  // },[navigate])

  // const Monthly = () => {
  //     var monthly_plan = $('#monthly_plan').text().trim();
  //     var monthly_plan_price = monthly_plan.split(' /')[0];

  //     const parts = monthly_plan_price.split('$');
  //     const numericValue = parseFloat(parts[1]) * 100; // Convert the numeric part to cents (e.g., 9.99 -> 999)
  //     const monthly_price = Math.floor(numericValue);

  //     const data = { monthly_price };
  //     axios
  //         .post(baseUrl + "/create-checkout-session", data)
  //         .then(response => {
  //             console.log(response);
  //             const checkoutUrl = response.data.checkoutUrl;
  //             window.location.href = checkoutUrl;
  //         })
  //         .catch(error => {
  //             console.log(error);
  //         });
  // };
  // const Week = () => {
  //     var week_plan = $('#week_plan').text().trim();
  //     var week_plan_price = week_plan.split(' /')[0];

  //     const parts = week_plan_price.split('$');
  //     const numericValue = parseFloat(parts[1]) * 100; // Convert the numeric part to cents (e.g., 9.99 -> 999)
  //     const week_price = Math.floor(numericValue);

  //     const data = { week_price };
  //     axios
  //         .post(baseUrl + "/create-checkout-session", data)
  //         .then(response => {
  //             console.log(response);
  //             const checkoutUrl = response.data.checkoutUrl;
  //             window.location.href = checkoutUrl;
  //         })
  //         .catch(error => {
  //             console.log(error);
  //         });
  // };
  // const Yearly = () => {
  //     var yearly_plan = $('#yearly_plan').text().trim();
  //     var yearly_plan_price = yearly_plan.split(' /')[0];

  //     const parts = yearly_plan_price.split('$');
  //     const numericValue = parseFloat(parts[1]) * 100; // Convert the numeric part to cents (e.g., 9.99 -> 999)
  //     const yearly_price = Math.floor(numericValue);

  //     const data = { yearly_price };
  //     axios
  //         .post(baseUrl + "/create-checkout-session", data)
  //         .then(response => {
  //             console.log(response);
  //             const checkoutUrl = response.data.checkoutUrl;
  //             window.location.href = checkoutUrl;
  //         })
  //         .catch(error => {
  //             console.log(error);
  //         });
  // };



  return (
    <div id="wrapper">
      <Header />
      <Plans />
      {/* <section className="tuition-section tuition-page-section">
        <div className="container container-c">
          <div className="row pb-5">
            <div className="page-title text-center">
              <h2 className="position-relative d-inline-block">Tuition</h2>
            </div>
          </div>
          <div className="row mt-4 tuition-box">
            {plans.map((plan) => (
              <div
                key={plan.id}
                className="col-xl-4 col-lg-6 col-md-6 col-sm-6"
              >
                <div className="tuition-price-box">
                  <article className="primary-bg text-center">
                    <h4>{plan.stripe_product}</h4>
                    {
                      plan.payment_schedule === "Week"?
                      <span>
                      ${plan.price_usd}{" "}
                      <span class="week-price">
                      1st week
                      <br></br>
                      then
                      <br></br>
                      $9.99/mo
                      </span>
                    </span>
                    :
                        <span>
                      ${plan.price_usd}{" "}
                      <span>
                        {plan.payment_schedule === "Yearly" ? "/ yr" : "/ mo"}
                      </span>
                    </span>
                      
                    }
                    
                    <ul className="list-price">
                      <li className="d-flex">
                        <span>
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span>Unlimited Access to Chrome Extension & Chat</span>
                      </li>
                      {plan.payment_schedule === "Yearly" && (
                        <li className="d-flex">
                          <span>
                            <i
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>Save 75%</span>
                        </li>
                      )}
                    </ul>
                    <button onClick={() => handlePlanStart(plan)}>
                      Start now
                    </button>
                  </article>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* <section className="call-us-section pt-0 pb-4">
        <div className="container">
          <div className="row primary-bg p-5">
            <div className="page-title text-center">
              <h2 className="mt-2 d-flex align-items-center justify-content-center position-relative">
                Try for <span className="d-flex position-relative">free!</span>
              </h2>
              <p className="mt-4 pt-2">
                Use Studi Guide AI for free on your first 3 homework questions
              </p>
              <button
                onClick={() => navigate("/register")}
                className="mt-4 d-flex justify-content-center"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </div>
  );

  // return (
  //     <div id="wrapper">
  //         <Header />
  //         <section className="tuition-section tuition-page-section">
  //             <div className="container container-c">
  //                 <div className="row pb-5">
  //                     <div className="page-title text-center">
  //                         <h2 className="position-relative d-inline-block">Tuition</h2>
  //                     </div>
  //                 </div>
  //                 <div className="row mt-4 tuition-box">
  //                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
  //                         <div className="tuition-price-box">
  //                             <article className="primary-bg text-center">
  //                                 <h4>Monthly</h4>
  //                                 <span id='monthly_plan'>$9.99 <span>/ mo</span></span>
  //                                 <ul className="list-price">
  //                                     <li className="d-flex">
  //                                         <span><i className="fa fa-check-circle" aria-hidden="true"></i></span>
  //                                         <span>Unlimited Access to Chrome Extension & Chat</span>
  //                                     </li>
  //                                 </ul>
  //                                 <button onClick={Monthly}>Start now</button>
  //                             </article>
  //                         </div>
  //                     </div>
  //                     <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
  //                         <div class="tuition-price-box">
  //                             <article class="primary-bg text-center">
  //                                 <h4>Trial</h4>
  //                                 <span id="week_plan">$1.00<span>/2 Trial</span></span>
  //                                 <ul class="list-price">
  //                                     <li class="d-flex">
  //                                         <span><i class="fa fa-check-circle" aria-hidden="true"></i></span>
  //                                         <span>Unlimited Access to Chrome Extension & Chat</span>
  //                                     </li>
  //                                 </ul>
  //                                 <button onClick={Week}>Start now</button>
  //                             </article>
  //                         </div>
  //                     </div>
  //                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
  //                         <div className="tuition-price-box">
  //                             <article className="primary-bg text-center">
  //                                 <h4>Yearly</h4>
  //                                 <span id='yearly_plan'>$59.99 <span>/ yr</span></span>
  //                                 <ul className="list-price">
  //                                     <li className="d-flex">
  //                                         <span><i className="fa fa-check-circle" aria-hidden="true"></i></span>
  //                                         <span>Unlimited Access to Chrome Extension & Chat</span>
  //                                     </li>
  //                                     <li className="d-flex">
  //                                         <span><i className="fa fa-check-circle" aria-hidden="true"></i></span>
  //                                         <span>Save 75%</span>
  //                                     </li>
  //                                 </ul>
  //                                 <button onClick={Yearly}>Start now</button>
  //                             </article>
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div>
  //         </section>

  //         <section className="call-us-section pt-0 pb-4">
  //             <div className="container">
  //                 <div className="row primary-bg p-5">
  //                     <div className="page-title text-center">
  //                         <h2 className="mt-2 d-flex align-items-center justify-content-center position-relative">Try for <span className="d-flex position-relative">free!</span></h2>
  //                         <p className="mt-4 pt-2">Use Studi Guide AI for free on your first 3 homework questions</p>
  //                         <button onClick={() => navigate("/register")} className="mt-4 d-flex justify-content-center">Get Started</button>
  //                     </div>
  //                 </div>
  //             </div>
  //         </section>
  //         <Footer />
  //     </div>
  // )
};

export default Price;
