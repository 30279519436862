import React from "react";
import { useNavigate } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import Plans from "./Plans";
import banner from "../assets/banner/banner-img.svg";
import Frame from "../assets/features/Frame.svg";
import Frame2 from "../assets/features/Frame2.svg";
import Frame3 from "../assets/features/Frame3.svg";
import Frame4 from "../assets/features/Frame4.svg";
import GIF_01 from "../assets/video/GIF_01.gif";
import GIF_02 from "../assets/video/GIF_02.gif";
import GIF_03 from "../assets/video/GIF_03.gif";
import GIF_04 from "../assets/video/GIF_04.gif";
// import WebsiteVideo_tiktok_1 from "../assets/video/WebsiteVideo_tiktok_1.mp4#t=0.001";
// import WebsiteVideo_tiktok_2 from "../assets/video/WebsiteVideo_tiktok_2.mp4#t=0.001";
// import WebsiteVideo_tiktok_3 from "../assets/video/WebsiteVideo_tiktok_3.mp4#t=0.001";
import "../assets/css/reset.css";
import "../assets/css/global.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";

const Home = () => {
  const navigate = useNavigate();
  const videoId = '7275412598164655402';
  const videoId2 = '7275413078110637358';
  const videoId3 = '7275491503491829038';
  return (
    <>
      <div id="wrapper">
        <Header />
        <section className="banner-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 d-flex align-items-center">
                <div className="banner-content d-flex justify-content-center flex-column">
                  <h1 className="fw-bold text-dark position-relative">
                    Finish Your Assignments 3x Faster
                  </h1>
                  <p>
                    Harness the power of A.I. technology to help you learn
                    faster.
                  </p>
                  <div className="g-btn-d">
                    <button
                      className="g-btn get-started-home"
                      onClick={() => navigate("/register")}
                    >
                      <span>Get Started</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                <div className="banner-img">
                  <img src={banner} alt="img"></img>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="action-section pt-0 pb-0">
          <div className="container container-c">
            <div className="row">
              <div className="page-title pb-5">
                <h2>See it in action</h2>
              </div>
            </div>
            <div className="row d-flex justify-content-between">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <article className="action-video-box">
                  <div className="action-video">
                    {/* <video width="400" preload="metadata" controls>
                      <source
                        src='https://www.tiktok.com/@testanswers.ai/video/7275412598164655402?_r=1&_t=8fRT9k5npde'
                        type="video/mp4"
                      />
                    </video> */}
                     <iframe title="TikTok Video" width="400" height="800" src={`https://www.tiktok.com/embed/v2/${videoId}?no_v=1`} autoplay="0" frameBorder="0" allowFullScreen></iframe>
                  </div>
                  {/* <div className="action-content">
                    <ul className="d-flex justify-content-between hedding-line">
                      <li className="d-flex align-items-center">
                        <i className="fa fa-commenting" aria-hidden="true"></i>{" "}
                        <span>Watch more exciting videos on TikTok</span>
                      </li>
                      <li>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@testanswers.ai"><button type="button" className="article-btn">Watch now</button></a>
                      </li>
                    </ul>
                    <hr></hr>
                    <ul className="">
                      <h4>@TestAnswers.Ai on TikTok</h4>
                      <p>
                      Tired of taking L’s on your college homework assignments? Try using TestAnswers.ai to get over the hump and make that Dean’s list! 😤
                      </p>
                      <p>
                        <i className="pe-1 fa fa-music" aria-hidden="true"></i>{" "}
                        original sound - Star Wars Galactic
                      </p>
                    </ul>
                  </div> */}
                </article>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <article className="action-video-box">
                  <div className="action-video">
                    {/* <video width="400" preload="metadata" controls>
                      <source
                        src={WebsiteVideo_tiktok_2}
                        type="video/mp4"
                      />
                    </video> */}
                    <iframe title="TikTok Video" width="400" height="800" src={`https://www.tiktok.com/embed/v2/${videoId2}?no_v=1`} frameBorder="0" allowFullScreen></iframe>
                  </div>
                  {/* <div className="action-content">
                    <ul className="d-flex justify-content-between hedding-line">
                      <li className="d-flex align-items-center">
                        <i className="fa fa-commenting" aria-hidden="true"></i>{" "}
                        <span>Watch more exciting videos on TikTok</span>
                      </li>
                      <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@testanswers.ai"><button type="button" className="article-btn">Watch now</button></a>
                      </li>
                    </ul>
                    <hr></hr>
                    <ul className="">
                      <h4>@TestAnswers.Ai on TikTok</h4>
                      <p>
                      Attention college students and high school students! TestAnswers.ai is all you need to study for you assignments! Start getting all A’s now!
                      </p>
                      <p>
                        <i className="pe-1 fa fa-music" aria-hidden="true"></i>{" "}
                        original sound - Star Wars Galactic
                      </p>
                    </ul>
                  </div> */}
                </article>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <article className="action-video-box">
                  <div className="action-video">
                    {/* <video width="400" preload="metadata" controls>
                      <source
                        src={WebsiteVideo_tiktok_3}
                        type="video/mp4"
                      />
                    </video> */}
                    <iframe title="TikTok Video" width="400" height="800" src={`https://www.tiktok.com/embed/v2/${videoId3}?no_v=1`} frameBorder="0" allowFullScreen></iframe>
                  </div>
                  {/* <div className="action-content">
                    <ul className="d-flex justify-content-between hedding-line">
                      <li className="d-flex align-items-center">
                        <i className="fa fa-commenting" aria-hidden="true"></i>{" "}
                        <span>Watch more exciting videos on TikTok</span>
                      </li>
                      <li>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@testanswers.ai"><button type="button" className="article-btn">Watch now</button></a>
                      </li>
                    </ul>
                    <hr></hr>
                    <ul className="">
                      <h4>@TestAnswers.Ai on TikTok</h4>
                      <p>
                      Doing your homework assignments with TestAnswers.ai is the ULTIMATE study hack and cheat code 😎😎😎
                      </p>
                      <p>
                        <i className="pe-1 fa fa-music" aria-hidden="true"></i>{" "}
                        // original sound - Star Wars Galactic
                      </p>
                    </ul>
                  </div> */}
                </article>
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="features-section">
          <div className="container container-c">
            <div className="row pb-5">
              <div className="page-title pb-5 max-width-t">
                <h2 className="position-relative">
                  The Features Students Have Been Looking For
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <article className="d-flex align-items-center flex-column text-center">
                  <img src={Frame} alt="img"></img>
                  <h4 className="mt-3 mb-3">A.I.-Powered Problem Solving</h4>
                  <p>
                  TestAnswers.AI leverages cutting-edge AI technology to deliver highly accurate answers to homework, quiz, and exam problems/questions.
                  </p>
                </article>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <article className="d-flex align-items-center flex-column text-center">
                  <img src={Frame2} alt="img"></img>
                  <h4 className="mt-3 mb-3">Multi-Platform Compatibility</h4>
                  <p>
                  The TestAnswers.AI extension seamlessly integrates with leading LMS platforms including Canvas, Blackboard, and many more, providing broad accessibility.
                  </p>
                </article>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-5">
                <article className="d-flex align-items-center flex-column text-center">
                  <img src={Frame3} alt="img"></img>
                  <h4 className="mt-3 mb-3">Knowledge Reinforcement</h4>
                  <p>
                  Each question and answer is accompanied by a comprehensive explanation, enabling students to further reinforce their understanding and enhance their learning experience.
                  </p>
                </article>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-5">
                <article className="d-flex align-items-center flex-column text-center">
                  <img src={Frame4} alt="img"></img>
                  <h4 className="mt-3 mb-3">Stealth Assistance</h4>
                  <p>
                  TestAnswers.AI operates in a private mode, sitting on top of a student's LMS platform rather than connecting to its API, ensuring students learn how they want to with our tools.
                  </p>
                </article>
              </div>
            </div>
          </div>
        </section>
        <section className="how-it-work-section pt-0">
          <div className="container container-c">
            <div className="row pb-5">
              <div className="page-title pb-5 text-center">
                <h2 className="position-relative d-inline-block">
                  How it works
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex align-items-center">
                <div className="how-it-work-box text-center">
                  <h4 className="position-relative hedding-h">1</h4>
                  <h4>Install TestAnswers AI from the <a href="https://chrome.google.com/webstore/detail/testanswers-ai/idmpanpgekfkgfhoikgdikhncnbiaalp" className="Google-Chrome-Store">Google Chrome Store <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" focusable="false" class="chakra-icon css-jq8xta" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></a></h4>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <img src={GIF_01} alt="img"></img>
              </div>
            </div>
            <div className="row d-f-r">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <img src={GIF_02} alt="img"></img>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex align-items-center">
                <div className="how-it-work-box text-center">
                  <h4 className="position-relative hedding-h">2</h4>
                  <h4>Activate the extension in your dashboard.</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex align-items-center">
                <div className="how-it-work-box text-center">
                  <h4 className="position-relative hedding-h">3</h4>
                  <h4>
                    Simply click the button by your question and watch us
                    deliver the answer and explanation.
                  </h4>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <img src={GIF_03} alt="img"></img>
              </div>
            </div>
            <div className="row d-f-r">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <img src={GIF_04} alt="img"></img>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex align-items-center">
                <div className="how-it-work-box text-center">
                  <h4 className="position-relative hedding-h">4</h4>
                  <h4>
                    Learning platform not directly supported? Just highlight the
                    question and right click to search.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Plans />
        {/* <section className="tuition-section pt-0 pb-0">
                    <div className="container container-c">
                        <div className="row pb-5">
                            <div className="page-title text-center">
                                <h2 className="position-relative d-inline-block">Tuition</h2>
                            </div>
                        </div>
                        <div className="row mt-4 tuition-box">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div className="tuition-price-box">
                                    <article className="primary-bg text-center">
                                        <h4>Monthly</h4>
                                        <span id='monthly_plan'>$9.99 <span>/ mo</span></span>
                                        <ul className="list-price">
                                            <li className="d-flex">
                                                <span><i className="fa fa-check-circle" aria-hidden="true"></i></span>
                                                <span>Unlimited Access to Chrome Extension & Chat</span>
                                            </li>
                                        </ul>
                                        <button onClick={Monthly}>Start now</button>
                                    </article>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div class="tuition-price-box">
                                    <article class="primary-bg text-center">
                                        <h4>Week</h4>
                                        <span id="week_plan">$1.00<span>/2 week</span></span>
                                        <ul class="list-price">
                                            <li class="d-flex">
                                                <span><i class="fa fa-check-circle" aria-hidden="true"></i></span>
                                                <span>Unlimited Access to Chrome Extension & Chat</span>
                                            </li>
                                        </ul>
                                        <button onClick={Week}>Start now</button>
                                    </article>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div className="tuition-price-box">
                                    <article className="primary-bg text-center">
                                        <h4>Yearly</h4>
                                        <span id='yearly_plan'>$59.99 <span>/ yr</span></span>
                                        <ul className="list-price">
                                            <li className="d-flex">
                                                <span><i className="fa fa-check-circle" aria-hidden="true"></i></span>
                                                <span>Unlimited Access to Chrome Extension & Chat</span>
                                            </li>
                                            <li className="d-flex">
                                                <span><i className="fa fa-check-circle" aria-hidden="true"></i></span>
                                                <span>Save 75%</span>
                                            </li>
                                        </ul>
                                        <button onClick={Yearly}>Start now</button>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
        <section className="faq-section" id="faq">
          <div className="container container-c">
            <div className="row pb-5">
              <div className="page-title pb-4 text-center">
                <h2 className="position-relative">
                  Frequently asked questions
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="faq-accordion">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Does it work on all learning platforms?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Our button is supported on the following learning
                        platforms: Canvas 2.0, McGraw Hill Connect, Blackboard,
                        D2L Brightspace, Moodle, Cengage MindTap.Learning
                        platform isn't listed? Just use our text selection
                        right-click feature to find answers on all other
                        Learning Platforms!
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Lockdown browsers?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                      Yes! TestAnswers.AI is compatible with lockdown browsers and functions seamlessly on top of the Learning Management System (LMS).
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Other Languages?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                      Yes! TestAnswers.AI will work in the following languages: English, Spanish, French, German, Italian, Dutch, Russian, Chinese, and Portuguese.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                         How long does the trial work?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                      The trial last for 2 weeks. After two weeks you will be automatically subscribed to the monthly subscription, which you can cancel at any time.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Can I promote TestAnswers AI?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                      Even better, you can get paid to promote TestAnswers.AI! <a href="https://docs.google.com/forms/d/1uve01SCwSWGqqckT_piPj3Qr2GNy-sKr6NGsFKbpaOQ/viewform?pli=1&pli=1&edit_requested=true"><button id="get_in_paid" type="button">Click here</button></a> to learn how!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Home;
