import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRouts = () => {

    function getCookie(name) {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
          const [cookieName, cookieValue] = cookie.trim().split('=');
          if (cookieName === name) {
            return cookieValue;
          }
        }
        return null;
      }
  
  const isLoggedin = getCookie('isLoggedin') || false;
  //console.log('isLoggedin ==',isLoggedin)

  return isLoggedin ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRouts;