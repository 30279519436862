import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Header from './Header';
import Footer from './Footer';
import validate from "validate.js";
import { toast } from 'react-toastify';
const baseUrl = process.env.REACT_APP_BASE_URL;

function ResetPasswordPage() {
  const [user_email, setuser_email] = useState('');
  const [expiration_datetime, setuser_expiration_datetime] = useState('');
  const [errors, setErrors] = useState({});
  //console.log('user_email', user_email);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State to track loading
  useEffect(() => {
    const currentURL = window.location.href;
    var token = '';
    if (currentURL.indexOf("token=") !== -1) {
      token = currentURL.split('token=')[1];
    }

    //   // Simulate passing of time (e.g., 30 minutes later)
    //   const currentTime = Date.now();
    //   const simulatedToken = {
    //     value: token.value,
    //     expirationTimestamp: currentTime + 1800000 // 30 minutes in milliseconds
    //   };
  
    //   function isTokenExpired(token) {
    //     return Date.now() > token.expirationTimestamp;
    //   }

    // if (isTokenExpired(simulatedToken)) {
    //   console.log("Token has expired");
    // } else {
    //   console.log("Token is still valid");
    // }

    axios.get(baseUrl + "/get-user-reset-password?token=" + token)
      .then(User => {
        //console.log('User_Info >>', User.data.User_Info);
        setuser_email(User.data.User_Info);
        setuser_expiration_datetime(User.data.User_Info.expiration_datetime);
        // setcancellationDetails(subscriptionInfo.data.cancellationDetails);
      })
      .catch(error => {
        console.log(error);
      });
  }, [navigate])

  const handleResetPassword = (event) => {
    // Validate password and confirm password
    event.preventDefault();
    const form = event.target;

    const formData = new FormData(form);
    const registerData = Object.fromEntries(formData.entries());
    const constraints = {
      password: {
        presence: true,
        length: { minimum: 6 },
      },
      conform_password: {
        presence: true,
        equality: "password",
      },
    };

    // Perform validation
    const validationErrors = validate(registerData, constraints);
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }
    // Clear old error messages if no validation errors
    setErrors({});

    const email = event.target.email.value;
    const password = event.target.password.value;
    const conform_password = event.target.conform_password.value;
    const data = { email, password, conform_password };
    //console.log("data >>", data);
    
    if (Date.now() > expiration_datetime) {
      toast.error('Token has expired', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (password !== conform_password) {
      // Handle password mismatch error
      toast.error('Password and Confirm Password do not match', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // Send the new password to the server for resetting
    // You can use Axios or another HTTP library for this
    // Example code:
    /*
    axios.post('/api/reset-password', { newPassword })
      .then((response) => {
        // Handle success
      })
      .catch((error) => {
        // Handle error
      });
    */
    setLoading(true); // Show loader
    axios.post(baseUrl + "/update-reset-password", data)
      .then((response) => {
        //console.log(response);
        setLoading(false); // Hide loader
        toast.success('Password reset successful', {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/login");
        event.target.reset();
      })
      .catch((error) => {
        setLoading(false); // Hide loader
        toast.error('Password reset failed', {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(error);
      });

  };

  return (
    <div>
      <Header />

      <section className="login-form-section">
        <div className="container">
          <div className="login-form global-fom primary-bg page-title">
            <form action="" onSubmit={handleResetPassword}>
              <div className="page-title pb-4 text-center">
                <h2 className="position-relative">Reset Password</h2>
              </div>
              <div className="mb-3 pt-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Email<span className="ps-2 email-star">*</span>{" "}
                </label>
                <input type="email" value={user_email ? user_email.user_email : ''} disabled className="form-control not-allowed" name="email" id="exampleFormControlInput1" placeholder="you@example.com" />
              </div>
              <div className="mb-3 pt-3">
                <label for="exampleFormControlInput1" className="form-label" >
                  Password<span className="ps-2 email-star">*</span>{" "}
                </label>
                <input type="password" required className="form-control" name="password" id="password" placeholder="••••••••" />
                {errors.password && (
                  <p className="text-danger">{errors.password.join(", ")}</p>
                )}
              </div>
              <div className="mb-3 pt-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Confirm Password<span className="ps-2 email-star">*</span>{" "}
                </label>
                <input type="password" required className="form-control" name="conform_password" id="conform_password" placeholder="••••••••" />
                {errors.conform_password && (
                  <p className="text-danger">{errors.conform_password.join(", ")}</p>
                )}
              </div>
              <button type="submit" className="login-btn" disabled={loading}>{loading ? "Loading..." : "Reset Password"}</button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ResetPasswordPage;
