import React from "react";
import { useState } from "react";
import { useNavigate, Navigate} from "react-router-dom";
import axios from "axios";
import Footer from "./Footer";
import validate from "validate.js";
import Header from "./Header";
import "../assets/css/reset.css";
import "../assets/css/global.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";

//const baseUrl = "https://www.testanswers.ai";

const baseUrl = process.env.REACT_APP_BASE_URL;



const Register = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // State to track loading
  const isLoggedin = getCookie('isLoggedin') || false;
  //console.log('isLoggedin == ',isLoggedin)
    if(isLoggedin){
      //navigate("/dashboard");
      return <Navigate to= '/dashboard' />;
    }

    function getCookie(name) {
      const cookies = document.cookie.split(';');
      for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.trim().split('=');
        if (cookieName === name) {
          return cookieValue;
        }
      }
      return null;
    }

  const SubmitHandler = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const password = event.target.password.value;
    const conform_password = event.target.conform_password.value;
    const data = { name, email, password, conform_password };

    event.preventDefault();
    const form = event.target;

    const formData = new FormData(form);
    const registerData = Object.fromEntries(formData.entries());

    // Define validation rules
    const constraints = {
      name: {
        presence: true,
      },
      email: {
        presence: true,
        email: true,
      },
      password: {
        presence: true,
        length: { minimum: 6 },
      },
      conform_password: {
        presence: true,
        equality: "password",
      },
    };

    // Perform validation
    const validationErrors = validate(registerData, constraints);
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }
    // Clear old error messages if no validation errors
    setErrors({});
    setLoading(true); // Show loader
    axios
      .post(baseUrl + "/signup", data)
      .then((response) => {
        //console.log(response);
        setLoading(false); // Hide loader
        navigate("/login");
        event.target.reset();
      })
      .catch((error) => {
        setLoading(false); // Hide loader
        console.log(error);
      });
  };
  return (
    <>
      <div>
        <div id="wrapper">
          <Header />
          <section className="login-form-section">
            <div className="container">
              <div className="login-form global-fom primary-bg page-title">
                <form onSubmit={SubmitHandler}>
                  <div className="page-title pb-4 text-center">
                    <h2 className="position-relative">Sign Up</h2>
                  </div>
                  <div className="mb-3 pt-2 ">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Name<span className="ps-2 email-star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="John Doe"
                    />
                    {errors.name && (
                      <p className="text-danger">{errors.name.join(", ")}</p>
                    )}
                  </div>
                  <div className="mb-3 pt-2">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Email<span className="ps-2 email-star">*</span>{" "}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="you@example.com"
                    />
                      {errors.email && (
                      <p className="text-danger">{errors.email.join(", ")}</p>
                    )}
                  </div>
                  <div className="mb-3 pt-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Password<span className="ps-2 email-star">*</span>{" "}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                    />
                      {errors.password && (
                      <p className="text-danger">{errors.password.join(", ")}</p>
                    )}
                  </div>
                  <div className="mb-3 pt-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Confirm Password<span className="ps-2 email-star">*</span>{" "}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="conform_password"
                      id="conform_password"
                      placeholder="••••••••"
                    />
                      {errors.conform_password && (
                      <p className="text-danger">{errors.conform_password.join(", ")}</p>
                    )}
                  </div>

                  {/* {Object.keys(errors).map((fieldName) => (
                    <p key={fieldName} className="text-danger">
                      {errors[fieldName].join(", ")}
                    </p>
                  ))} */}

                  <div className="login-google-btn pt-4">
                    <ul>
                      <li>
                        <button type="submit" className="login-btn" disabled={loading}>
                        {loading ? "Loading..." : "Sign Up"}
                        </button>
                      </li>
                      {/* <li><button className="google-with mt-3"><img src={google_icon} alt="img" /> Continue with google</button></li> */}
                    </ul>
                    <p className="text-center">
                      Already have an account?{" "}
                      <button onClick={() => navigate("/login")}>Login</button>{" "}
                      Instead
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Register;
