import React from 'react'
import Header from './Header'
import Footer from './Footer'
import search_h_icon from '../assets/search/search-h-icon.svg';
import search_icon from '../assets/search/search-icon.svg';
import list_01 from '../assets/search/list-01.svg';
import list_02 from '../assets/search/list-02.svg';
import list_03 from '../assets/search/list-03.svg';
import list_04 from '../assets/search/list-04.svg';
import search_add from '../assets/search/search-add.svg';
import '../assets/css/reset.css';
import '../assets/css/global.css';
import '../assets/css/style.css';
import '../assets/css/responsive.css';

const Search = () => {
  return (
    
    <div id="wrapper">
        <Header/>
        <section className="search-section-section">
            <div className="container">
                <div className="global-fom primary-bg page-title">
                    <form action="">
                        <div className="page-title pb-4 text-center">
                            <h2 className="position-relative d-flex"><img src={search_h_icon} alt="img" /> Search Our Question & Answer Database</h2>
                        </div>
                        <div className="mb-3 pt-2">
                            <span className="search-span d-flex position-relative"><img src={search_icon} alt="img" /><input type="text" className="form-control" id="exampleFormControlInput1" placeholder="The US Constitution defines citizenship in the..." /></span>
                          </div>
                          <div className="search-list">
                            <ul className="d-flex gap-4 pt-4">
                                <li>
                                    <p className="d-flex">
                                        <span className="d-block icon"><img src={list_01} alt="img" /></span>
                                        <span className="d-block">Completely free to use with no limits</span>
                                    </p>
                                </li>
                                <li>
                                    <p className="d-flex">
                                        <span className="d-block icon"><img src={list_02} alt="img" /></span>
                                        <span className="d-block">600,000+ questions & answers</span>
                                    </p>
                                </li>
                                <li>
                                    <p className="d-flex">
                                        <span className="d-block icon"><img src={list_03} alt="img" /></span>
                                        <span className="d-block">Hundreds of different subjects</span>
                                    </p>
                                </li>
                                <li>
                                    <p className="d-flex">
                                        <span className="d-block icon"><img src={list_04} alt="img" /></span>
                                        <span className="d-block">Lightning fast search speeds</span>
                                    </p>
                                </li>
                            </ul>
                          </div>
                    </form>
                    </div>
                    </div>
        </section>

        <section className="search-section pb-0 pt-0">
            <div className="container"> 
            <div className="row">
            <img src={search_add} className="ms-auto me-auto" alt="img" />
        </div>
    </div>
        </section>
    <Footer/>
</div>
  )
}

export default Search
