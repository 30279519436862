import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo/logo.svg";
import tiktok from "../assets/social/tiktok.svg";
import instagam from "../assets/social/instagam.svg";
import "../assets/css/reset.css";
import "../assets/css/global.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section className="call-us-section pt-0 pb-0">
        <div className="container">
          <div className="row primary-bg p-5">
            <div className="page-title text-center">
              <h2 className="mt-2 d-flex align-items-center justify-content-center position-relative">
                Try Now For Free For 2 Weeks
              </h2>
              <p className="mt-4 pt-2">
              2 weeks trial to get started with <br/>TestAnswers AI !
              </p>
              <button
                onClick={() => navigate("/register")}
                className="mt-4 d-flex justify-content-center"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="container">
          <div className="row footer-info">
            <div className="footer-logo">
              <button className="d-flex align-items-center">
                <img src={logo} alt="img"></img>TestAnswers AI
              </button>
            </div>
            <div className="social">
              <ul className="d-flex">
                <li>
                  {/* <button>
                    https://www.tiktok.com/@testanswers.ai  
                  </button> */}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@testanswers.ai"><button type="button"><img src={tiktok} alt="img"></img></button></a>
                </li>
                <li>
                  {" "}
                  <button>
                    <img src={instagam} alt="img"></img>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="row footer-menus">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
              <div className="footer-menu">
                <h4>Products</h4>
                <ul>
                  <li>
                    <a href="/#features"><button>Features</button></a>
                  </li>
                  <li>
                    {/* <button onClick={() => navigate("/pricing")}>
                      Pricing
                    </button> */}
                    <a href="/#pricing"><button type="button">Pricing</button></a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://chrome.google.com/webstore/detail/testanswers-ai/idmpanpgekfkgfhoikgdikhncnbiaalp"><button type="button">Download Chrome Extension</button></a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
              <div className="footer-menu">
                <h4>Platform</h4>
                <ul>
                  <li>
                    <button onClick={() => navigate("/login")}>Login</button>
                  </li>
                  <li>
                    <button onClick={() => navigate("/register")}>
                      Sign up
                    </button>
                  </li>
                  {/* <li>
                    <button>Recover</button>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
              <div className="footer-menu">
                <h4>Resources</h4>
                <ul>
                  <li>
                    <a
                      className="contact-open"
                      href="mailto:TestAnswersai@gmail.com"
                    >
                      <button>Contact us</button>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/1uve01SCwSWGqqckT_piPj3Qr2GNy-sKr6NGsFKbpaOQ/viewform?pli=1&pli=1&edit_requested=true"><button type="button">Promote & Get Paid</button></a>
                  </li>
                  <li>
                    {/* <button>FAQ</button> */}
                    <a href="/#faq"><button type="button">FAQ</button></a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
              <div className="footer-menu">
                <h4>Legal</h4>
                <ul>
                  <li>
                    <button onClick={() => navigate("/terms")}>
                      Terms of Use
                    </button>
                  </li>{" "}
                  <li>
                    <button onClick={() => navigate("/privacy")}>
                      Privacy Policy
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <p>© 2023 - TestAnswers AI. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
