import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../assets/logo/logo.svg";
import "../assets/css/reset.css";
import "../assets/css/global.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
const oneDay = 1000 * 60 * 60 * 24;
const baseUrl = process.env.REACT_APP_BASE_URL;
const Header = () => {
  const [isLoggedIn, setLoggedIn] = useState();
  function getCookie(name) {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split("=");
      if (cookieName === name) {
        return setLoggedIn(decodeURIComponent(cookieValue));
      }
    }
    return null;
  }

  // Usage
  useEffect(() => {
    getCookie("isLoggedin");
  }, []);

  //console.log(isLoggedIn);
  const handleAuth = () => {
    if (isLoggedIn) {
      axios
        .get(baseUrl + "/logout")
        .then((response) => {
          //console.log(response);
          document.cookie =
            `isLoggedin=; max-age=` +
            oneDay +
            `; secure; samesite=none; path=/`;
          document.cookie =
            `userId=; max-age=` + oneDay + `; secure; samesite=none; path=/`;
          navigate("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      navigate("/login");
    }
  };
  const navigate = useNavigate();
  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <div className="logo ">
              <button
                className="navbar-brand d-flex align-items-center"
                onClick={() => navigate("/")}
              >
                <img src={logo} alt="img"></img>TestAnswers AI
              </button>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  mb-2 mb-lg-0 ms-auto d-flex align-items-center">
                {/* <li className="nav-item">
                        <button className="nav-link active" aria-current="page">Chat</button>
                      </li>
                      <li className="nav-item">
                      <button className="nav-link" onClick={() => navigate("/search")} >Search</button>
                      </li> */}
                <li className="nav-item"><a target="_blank" rel="noopener noreferrer" href="https://chrome.google.com/webstore/detail/testanswers-ai/idmpanpgekfkgfhoikgdikhncnbiaalp"><button type="button" className="nav-link">Download Chrome Extension</button></a></li>
                <span className="header-border"></span>
                <li className="nav-item">
                  {/* <button
                    className="nav-link"
                    onClick={() => navigate("/pricing")}
                  >
                    Price
                  </button> */}
                  <a href="/#pricing"><button type="button" className="nav-link">Pricing</button></a>
                </li>
                <span className="header-border"></span>
                <li className="nav-item">
                  <button className="nav-link" onClick={handleAuth}>
                    {isLoggedIn ? "Log Out" : "Login"}
                  </button>
                </li>
                <span className="header-border"></span>
                <li className="nav-item">
                  <button
                    className="nav-link g-btn"
                    onClick={() => navigate("/register")}
                  >
                    <span>Get Started</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
