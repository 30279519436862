import React, { useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { toast } from 'react-toastify';

const baseUrl = process.env.REACT_APP_BASE_URL;
function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false); // State to track loading
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleForgotPassword = async (event) => {
        event.preventDefault();
        setLoading(true); // Show loader
        try {
            const response = await axios.post(baseUrl + '/reset-password', { email });
            setLoading(false); // Hide loader
            console.log(response.data); // Success message or error message from the server
            toast.success('Password Reset Email Sent', {
              position: toast.POSITION.TOP_RIGHT,
            });
        } catch (error) {
            console.error(error);
            setLoading(false); // Hide loader
            toast.error('Email Not Found', {
              position: toast.POSITION.TOP_RIGHT,
            });
            //alert(error);
        }
    };

    return (
        <div>
            <Header />
            <section className="login-form-section">
        <div className="container">
          <div className="login-form global-fom primary-bg page-title">
            <form action="" onSubmit={handleForgotPassword}>
              <div className="page-title pb-4 text-center">
                <h2 className="position-relative">Recover Account</h2>
              </div>
              <div className="mb-3 pt-3">
              <label for="exampleFormControlInput1" className="form-label">
                  Email<span className="ps-2 email-star">*</span>{" "}
                </label>
                <input onChange={handleEmailChange} type="email" required className="form-control" name="email" id="exampleFormControlInput1" placeholder="you@example.com"/>

              </div>
              <button type="submit" className="login-btn" disabled={loading}>{loading ? "Loading..." : "Send email"}</button>
            </form>
          </div>
        </div>
      </section>
            <Footer />
        </div>
    );
}

export default ForgotPassword;
